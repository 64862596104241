import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { ChangeText } from '../../interfaces/amendment/change-text';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';

@Component({
  selector: 'lib-change-text',
  templateUrl: './change-text.component.html',
  styleUrls: ['./change-text.component.css']
})
export class ChangeTextComponent implements OnInit, OnDestroy {
  protected changeText: ChangeText[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) { }
  
  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => this.changeText = order?.amendment?.changeText ?? []);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
