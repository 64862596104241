import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JurisdictionSelectionComponent } from '../jurisdiction-selection/jurisdiction-selection.component';
import { BehaviorSubject } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { Address } from '../../interfaces/address/address';
import { ContactDetailsUIConfiguration } from '../../interfaces/ui-configurations/contact-details-ui-configuration';

@Component({
  selector: 'lib-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit, AfterViewInit {
  @Input() address!: Address;
  @Input() uiConfiguration: ContactDetailsUIConfiguration | null | undefined;
  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  public addressDetails!: FormGroup;

  streetNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  streetNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  unitError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  cityError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  postalCodeError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  constructor(private formBuilder: FormBuilder) {}

  initForm() {
    this.addressDetails = this.formBuilder.group({
      streetNumber: [this.address.streetNumber],
      streetName: [this.address.streetName],
      unit: [this.address.unit],
      city: [this.address.city],
      postalCode: [this.address.postalCode]
    });
  }

  initJurisdictionsForm() {
    let countryControl = this.jurisdictionSelectionComponent.countrySelectionForm.controls['Country'];
    let jurisdictionControl = this.jurisdictionSelectionComponent.countrySelectionForm.controls['Jurisdiction'];

    this.addressDetails.setControl('countryCode', countryControl);
    this.addressDetails.setControl('jurisdiction', jurisdictionControl);

    if (this.address.countryCode) {
      this.jurisdictionSelectionComponent.setCountrySelection(this.address.countryCode);
    }

    if (this.address.jurisdiction) {
      this.jurisdictionSelectionComponent.setJurisdictionSelection(this.address.jurisdiction);
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.initJurisdictionsForm();
  }

  setCountrySelection(country: string) {
    this.jurisdictionSelectionComponent.setCountrySelection(country);
  }

  setJurisdictionSelection(jurisdiction: string) {
    this.jurisdictionSelectionComponent.setJurisdictionSelection(jurisdiction);
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.jurisdictionSelectionComponent.pushErrors(errors);

    this.streetNumberError$.next(errors?.filter(error => error.path.includes('/streetnumber')).at(0)?.userFriendlyMessage);
    this.streetNameError$.next(errors?.filter(error => error.path.includes('/streetname')).at(0)?.userFriendlyMessage);
    this.unitError$.next(errors?.filter(error => error.path.includes('/unit')).at(0)?.userFriendlyMessage);
    this.cityError$.next(errors?.filter(error => error.path.includes('/city')).at(0)?.userFriendlyMessage);
    this.postalCodeError$.next(errors?.filter(error => error.path.includes('/postalcode')).at(0)?.userFriendlyMessage);
  }
}
