import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-lien-additional-information',
  templateUrl: './lien-additional-information.component.html',
  styleUrls: ['./lien-additional-information.component.css']
})
export class LienAdditionalInformationComponent {
  order!: Order;
  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) {}

  ngOnInit(): void {
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => this.order = order);
  }
  
  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}