import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { Observable, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { Party } from '../../interfaces/parties/party';
import { DatePipe } from '@angular/common';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ePartyType } from '../../enums';
import { ReusableParty } from '../../interfaces/reusable-parties/reusable-party';
import { PartyForCreation } from '../../interfaces/parties/party-for-creation';

@Component({
  selector: 'lib-parties-list',
  templateUrl: './parties-list.component.html',
  styleUrls: ['./parties-list.component.css'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PartiesListComponent {
  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() expandedParty: Party | null = null;

  @Input() isEditable: boolean = false;

  @Input() businessPartyType!: ePartyType;
  @Input() individualPartyType!: ePartyType;
  @Input() partyTypeTitle: 'Debtor' | 'Secured Party' | 'Dealer' | 'Registering Agent' | 'Party' = 'Party';
  @Input() defaultPartyType: ePartyType = this.businessPartyType;

  @Output() partyAddedEvent = new EventEmitter<Party>();
  @Output() partyUpdatedEvent = new EventEmitter<Party>();
  @Output() partyCopiedEvent = new EventEmitter<Party>();
  @Output() partyRemovedEvent = new EventEmitter<Party>();
  @Output() partyFormValueChangedEvent = new EventEmitter<Party>();
  @Output() reusablePartySavedEvent = new EventEmitter<PartyForCreation>();

  @Input() observableParties$!: Observable<Party[]>;
  @Input() reusableParties: ReusableParty[] = [];


  displayedColumns: string[] = ['name', 'party-type', 'contactDetails'];
  isLoading: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private emptyEntityService: EmptyEntityService) { }

  ngOnInit(): void {
    if (this.uiConfiguration.showEstate) {
      this.displayedColumns = this.displayedColumns.concat(['estate']);
    }

    if (this.uiConfiguration.showRegistryCode) {
      this.displayedColumns = this.displayedColumns.concat(['registry-code']);
    }

    if (this.isEditable) {
      this.displayedColumns = this.displayedColumns.concat(['actions']);
    }
  }

  onEditButtonClick = (event: Event, partyToEdit: Party) => {
    if (this.isEditable) {
      this.expandedParty = this.expandedParty === partyToEdit ? null : partyToEdit;
    }
  }

  onDeleteButtonClick = (event: Event, partyToDelete: Party, rowIndex: number) => {
    // emit this party to delete
    this.partyRemovedEvent.emit(partyToDelete);
  }

  onCopyButtonClick = (event: Event, partyToCopy: Party) => {

    // emit this party to copy
    this.partyCopiedEvent.emit(partyToCopy);
  }

  onUndoButtonClick = (event: Event, partyToUndo: Party, rowIndex: number) => {

    // emit this party to delete
    //this.partyRemovedEvent.emit(partyToUndo);

    // filter out deleted party from list of displayed parties
    //this.parties = this.parties.filter((u) => u.id !== partyToUndo.id);
    //this.order.parties = this.parties;
  }

  handlePartyUpdateEvent(party: Party) {
    this.partyUpdatedEvent.emit(party);
    this.expandedParty = null;
  }

  handleChangesCancelledEvent(party: Party) {
    this.expandedParty = null;
  }

  handlePartyFormValueChangedEvent(party: Party) {
    this.partyFormValueChangedEvent.emit(party);
  }

  handleExistingPartySaveEvent(existingParty: PartyForCreation) {
    this.reusablePartySavedEvent.emit(existingParty);
  }

  protected addNewParty() {
    let newParty = this.emptyEntityService.getEmptyParty(this.defaultPartyType, this.order?.id ?? '');
    this.partyAddedEvent.emit(newParty);
  }

  getName(party: Party) {
    let name: string | null | undefined;

    if (this.isIndividual(party)) {
      var fullName = "";
      if (party.middleName == null || party.middleName == "") {
        fullName = party.firstName + " " + party.lastName;
      } else {
        fullName = party.firstName + " " + party.middleName + " " + party.lastName;
      }

      if (party.generationID) {
        fullName += `, ${party.generationID.toString()}`;
      }

      if (party.dateOfBirth) {
        let formattedDateOfBirth = this.datePipe.transform(party.dateOfBirth, 'yyyy-MM-dd');
        fullName += ` (${formattedDateOfBirth})`;
      }

      name = fullName.trim();
    } else {
      name = party.busName?.trim();
    }

    if (name) {
      return name;
    } else {
      var fullType = this.getPartyTypeString(party);
      var splitType = fullType.split(' ');
      var type = splitType.slice(1).join(' ');

      return `New ${type}`;
    }
  }

  getAddress(party: Party) {
    const addressFields = [party.contactDetails?.address?.unit,
    party.contactDetails?.address?.streetNumber,
    party.contactDetails?.address?.streetName,
    party.contactDetails?.address?.city,
    party.contactDetails?.address?.jurisdiction,
    party.contactDetails?.address?.countryCode,
    party.contactDetails?.address?.postalCode
    ].filter(field => field);

    let result: string = '';
    const isJurisdictionNotNull = !!party.contactDetails?.address?.jurisdiction;

    for (let i = 0; i < addressFields.length; i++) {
      result += addressFields[i];

      if (isJurisdictionNotNull && addressFields[i] === party.contactDetails.address.jurisdiction) {
        result += ',';
      }

      if (i != addressFields.length - 1) {
        result += ' ';
      }
    }

    result = result.trim();

    if (result.startsWith(',')) {
      result = result.slice(1);
    }

    if (result.endsWith(',')) {
      result = result.slice(0, result.length - 2);
    }

    return result;
  }

  getContactDetails(party: Party) {

    let result: string = this.getAddress(party);

    if (party.contactDetails?.email) {
      if(result) {
        result += '<br>';
      }

      result += `Email: ${party.contactDetails.email}`
    }

    if (party.contactDetails?.phoneNumber) {
      if(result) {
        result += '<br>';
      }

      result += `Phone: ${party.contactDetails.phoneNumber}`
    }

    if (party.contactDetails?.faxNumber) {
      if(result) {
        result += '<br>';
      }

      result += `Fax: ${party.contactDetails.faxNumber}`
    }

    return result;
  }

  getPartyTypeString(party: Party) {
    switch (party.partyTypeID ?? party.partyType.id) {
      case ePartyType.businessDebtor:
        return "Business Debtor";
      case ePartyType.individualDebtor:
        return "Individual Debtor";
      case ePartyType.businessSecuredParty:
        return "Business Secured Party";
      case ePartyType.individualSecuredParty:
        return "Individual Secured Party";
      case ePartyType.businessRegisteringAgent:
        return "Business Registering Agent";
      case ePartyType.individualRegisteringAgent:
        return "Individual Registering Agent";
      case ePartyType.individualDealer:
        return "Individual Dealer";
      case ePartyType.businessDealer:
        return "Business Dealer";
      default:
        return "Party";
    }
  }

  isIndividual(party: Party) {
    return party.partyTypeID == ePartyType.individualDebtor ||
      party.partyTypeID == ePartyType.individualSecuredParty ||
      party.partyTypeID == ePartyType.individualDealer ||
      party.partyTypeID == ePartyType.individualRegisteringAgent;
  }

  isEstate(party: Party) {
    if (this.isIndividual(party)) {
      return party.isEstate ? "YES" : "NO";
    } else {
      return "";
    }
  }

  pluralizePartyTypeTitle(): string {
    let title = this.partyTypeTitle.toLowerCase();

    if(title.endsWith('y')) {
      title = title.substring(0, title.length - 1) + 'ies';
    } else {
      title = title + 's';
    }

    return title;
  }
}
