<div class="padding">
    <form [formGroup]="partyDetails">
        <div class="flex-container">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'partyTypeID'"
                    aria-label="Debtor Individual or Business radio option">
                    <mat-radio-button name="individual-selection" class="radio-button"
                        [value]="individualPartyType">Individual {{ partyTypeTitle }}</mat-radio-button>
                    <mat-radio-button name="business-selection" class="radio-button"
                        [value]="businessPartyType">Business
                        {{ partyTypeTitle }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="flex-item form-field" *ngIf="uiConfiguration.showEstate && displayIndividualFields">
                <mat-checkbox [formControlName]="'isEstate'" name="is-estate">
                    Estate
                </mat-checkbox>
            </div>
        </div>

        <div *ngIf="displayIndividualFields" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput [formControlName]="'firstName'" name="first-name" placeholder="First Name" />
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['firstName']"
                    [message]="firstNameError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input matInput [formControlName]="'middleName'" name="middle-name" placeholder="Middle Name" />
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['middleName']"
                    [message]="middleNameError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput [formControlName]="'lastName'" name="last-name" placeholder="Last Name" />
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['lastName']"
                    [message]="lastNameError$"></lib-error-bubble>
            </div>

            <div *ngIf="uiConfiguration.showGeneration && generations.length > 0"
                class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Generation</mat-label>
                    <mat-select formControlName="generationID" placeholder="Generation">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let gen of generations" [value]="gen.value">
                            {{ gen.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['generationID']"
                    [message]="generationError$"></lib-error-bubble>
            </div>
        </div>
        <div *ngIf="displayIndividualFields" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Birth Date</mat-label>
                    <input matInput [matDatepicker]="dateOfBirth" [formControlName]="'dateOfBirth'" name="date-of-birth"
                        placeholder="YYYY-MM-DD" />
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['dateOfBirth']"
                    [message]="dateOfBirthError$"></lib-error-bubble>
            </div>
        </div>
        <div *ngIf="displayBusinessFields" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Business Name</mat-label>
                    <input matInput [formControlName]="'busName'" name="business-name" placeholder="Business Name" />
                </mat-form-field>
                <lib-error-bubble [control]="partyDetails.controls['busName']"
                    [message]="busNameError$"></lib-error-bubble>
            </div>
        </div>

        <form [formGroup]="contactDetails">
            <div *ngIf="uiConfiguration.contactDetailsConfiguration.showEmail" class="flex-container">
                <div class="validated-form-field-container flex-container">
                    <mat-form-field class="flex-item form-field" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput [formControlName]="'email'" name="email" placeholder="Email">
                    </mat-form-field>
                    <lib-error-bubble [control]="contactDetails.controls['email']"
                        [message]="emailError$"></lib-error-bubble>
                </div>
            </div>

            <div *ngIf="uiConfiguration.contactDetailsConfiguration.showPhoneNumber || uiConfiguration.contactDetailsConfiguration.showFaxNumber"
                class="flex-container">
                <div *ngIf="uiConfiguration.contactDetailsConfiguration.showPhoneNumber"
                    class="validated-form-field-container flex-container">
                    <mat-form-field class="flex-item form-field" appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input matInput [formControlName]="'phoneNumber'" name="phoneNumber" placeholder="111-222-3333">
                    </mat-form-field>
                    <lib-error-bubble [control]="contactDetails.get('phoneNumber')!"
                        [message]="phoneNumberError$"></lib-error-bubble>
                </div>

                <div *ngIf="uiConfiguration.contactDetailsConfiguration.showFaxNumber"
                    class="validated-form-field-container flex-container">
                    <mat-form-field class="flex-item form-field" appearance="outline">
                        <mat-label>Fax Number</mat-label>
                        <input matInput [formControlName]="'faxNumber'" name="faxNumber" placeholder="111-222-3333">
                    </mat-form-field>
                    <lib-error-bubble [control]="contactDetails.get('faxNumber')!"
                        [message]="faxNumberError$"></lib-error-bubble>
                </div>
            </div>

            <lib-address-form [address]="party.contactDetails.address"
                [uiConfiguration]="uiConfiguration.contactDetailsConfiguration"></lib-address-form>

        </form>
        <!-- Navigation buttons -->
        <div class="flex-container padding-top">
            <button mat-raised-button (click)="clearFields()" class="padding-sides flex-item form-field previous-button"
                aria-label="Previous step button">
                Clear Fields
            </button>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>

            <button *ngIf="showSaveButton" mat-raised-button (click)="saveParty()" color="primary"
                class="padding-sides flex-item form-field" aria-label="Next step button">
                Save {{ partyTypeTitle }}
            </button>
            <!-- [class.spinner]="this.isSaving"
                [disabled]="this.isSaving" -->
        </div>
    </form>
</div>