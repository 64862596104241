<h2 mat-dialog-title>General Collateral History</h2>
<mat-dialog-content>
    <div *ngFor="let node of history; let i = index;">
        <div class="margin-bottom-15" *ngIf="i === 0 || (node.collateralValue && node.registrationNumber)">
            <div class="light-bold margin-bottom-5">{{ i === 0 ? 'Original' : 'Replaced by registration number ' +
                node.registrationNumber }}:</div>
            <span *ngIf="node.collateralValue; else none">{{ node.collateralValue }}</span>
        </div>
        <div *ngIf="i > 0 && node.collateralToAdd && node.registrationNumber" class="margin-bottom-15">
            <div class="light-bold margin-bottom-5">Added by registration number {{ node.registrationNumber }}:</div>
            <span>{{ node.collateralToAdd }}</span>
        </div>
        <div *ngIf="i > 0 && node.collateralToRemove && node.registrationNumber" class="margin-bottom-15">
            <div class="light-bold margin-bottom-5">Removed by registration number {{ node.registrationNumber }}:</div>
            <span>{{ node.collateralToRemove }}</span>
        </div>
    </div>
    <ng-template #none>
        <span class="text-faded">No collateral found.</span>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="flex-container-right-align">
    <div class="spacer"></div>
    <button class="flex-item-fixed-size" mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>