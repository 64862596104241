<div>
    <!-- Default Secured Party Handler -->
    <lib-handle-existing-party *ngIf="this.reusableParties.length > 0"
        [existingParties]="this.reusableParties"
        (existingPartySavedEvent)="handleExistingPartySaveEvent($event)"
        class="flex-item">
    </lib-handle-existing-party>

    <table *ngIf="(observableParties$ | async)?.length ?? 0; else noParties" mat-table [dataSource]="observableParties$" multiTemplateDataRows >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let party" (click)="onEditButtonClick($event, party)"> {{ this.getName(party) }} </td>
        </ng-container>

        <ng-container matColumnDef="party-type">
            <th mat-header-cell *matHeaderCellDef> Party Type </th>
            <td mat-cell *matCellDef="let party" (click)="onEditButtonClick($event, party)"> {{ this.getPartyTypeString(party) }} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="contactDetails">
            <th mat-header-cell *matHeaderCellDef> Contact Details </th>
            <td mat-cell *matCellDef="let party" (click)="onEditButtonClick($event, party)">
                <span [innerHTML]="this.getContactDetails(party)"></span>
            </td>
        </ng-container>

        <!-- Estate Column -->
        <ng-container matColumnDef="estate">
            <th mat-header-cell *matHeaderCellDef> Estate </th>
            <td mat-cell *matCellDef="let party" (click)="onEditButtonClick($event, party)">
                <span [innerHTML]="this.isEstate(party)"></span>
            </td>
        </ng-container>

        <!-- Estate Column -->
        <ng-container matColumnDef="registry-code">
            <th mat-header-cell *matHeaderCellDef> Registry Code </th>
            <td mat-cell *matCellDef="let party" (click)="onEditButtonClick($event, party)">
                <span [innerHTML]="party.reusableRegistryCode"></span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let party; let i = index">
                <button color="primary" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditButtonClick($event, party)" aria-label="Edit secured party">
                        <mat-icon color="primary">edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="onCopyButtonClick($event, party)" aria-label="Copy secured party">
                        <mat-icon color="primary">content_copy</mat-icon>
                        <span>Copy</span>
                    </button>
                    <button mat-menu-item (click)="onDeleteButtonClick($event, party, i)" aria-label="Delete secured party">
                        <mat-icon color="warn">delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let party" [attr.colspan]="displayedColumns.length">
                <div class="expanded-entity-detail"
                    [@detailExpand]="party == expandedParty ? 'expanded' : 'collapsed'">
                    <div class="expanded-entity-information">
                            <lib-parties-form
                                *ngIf="isEditable"
                                [partyTypeTitle]="partyTypeTitle"
                                [order]="this.order"
                                [party]="party"
                                [errors$]="errors$"
                                [businessPartyType]="businessPartyType"
                                [individualPartyType]="individualPartyType"
                                [uiConfiguration]="uiConfiguration"
                                (partySavedEvent)="handlePartyUpdateEvent($event)"
                                (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)"
                                class="flex-item">
                            </lib-parties-form>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let party; columns: displayedColumns;" class="expandable-table-row"
            [class.example-expanded-row]="expandedParty === party">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
    </table>

    <ng-template #noParties>
        <div class="margin-bottom-25 margin-top-25 text-faded" [ngClass]="isEditable ? 'flex-container-center' : ''">
            <span class="padding-sides-5">No {{ pluralizePartyTypeTitle() }} found. </span><span *ngIf="isEditable">Please use the + button below to add
                some.</span>
        </div>
    </ng-template>

    <div *ngIf="isEditable" class="flex-container front margin-bottom-25">
        <div class="spacer"></div>
        <button (click)="addNewParty()" mat-mini-fab color="primary" class="translate-down" aria-label="Example icon button with a menu icon">
        <mat-icon>add</mat-icon>
        </button>
        <div class="spacer"></div>
    </div>

    <div *ngIf="isLoading">
        <mat-spinner class="center"></mat-spinner>
    </div>
</div>